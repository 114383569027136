import {
  CompanyStatusEnum,
  CustomField,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
} from 'shared-domain';

export const companyFields: CustomField[] = [
  {
    // This field is mandatory to create a company
    id: 'country',
    type: FieldTypeEnum.country,
    isRequired: true,
    isEnabled: true,
  },
  {
    // This field is mandatory to create a company
    id: 'name',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'commercial_name',
    isRequired: false,
    isEnabled: true,
  },
  {
    // This field is mandatory to create a company
    id: 'registration_number',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'legal_form',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'status',
    type: FieldTypeEnum.select,
    isRequired: false,
    isEnabled: false,
    options: [
      CompanyStatusEnum.live,
      CompanyStatusEnum.closed,
      CompanyStatusEnum.not_reported,
    ],
  },
  {
    id: 'registration_date',
    type: FieldTypeEnum.date,
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'tax_identification_number',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'website_url',
    type: FieldTypeEnum.url,
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'employer_identification_number',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'share_capital',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'us_links',
    type: FieldTypeEnum.radio,
    options: ['true', 'false'],
    isRequired: true,
    isEnabled: true,
    entityType: FieldEntityTypeEnum.individual,
    propertyType: FieldPropertyTypeEnum.custom,
  },
  {
    id: 'code',
    nested: 'classifications',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'description',
    nested: 'classifications',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'street_address',
    nested: 'address',
    isRequired: false,
    isEnabled: true,
  },
  {
    id: 'street_address_2',
    nested: 'address',
    isRequired: false,
    isEnabled: true,
  },
  {
    id: 'postal_code',
    nested: 'address',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'city',
    nested: 'address',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'state',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'region',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'country',
    nested: 'address',
    type: FieldTypeEnum.country,
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'iban',
    nested: 'banking_information',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'bic',
    nested: 'banking_information',
    isRequired: false,
    isEnabled: false,
  },
];
