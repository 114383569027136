import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: 'introduction',
    type: StepTypeEnum.text,
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: BaseStepIdEnum.affiliated_companies_list,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true },
  },
  // Enable the individual_edit step only for a KYC
  /*{
    id:  BaseStepIdEnum.individual_edit,
  },*/
  {
    id: 'busniess_nature_1',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'Business activities',
          type: FieldTypeEnum.textarea,
          hasHelper: true,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'provides_payment_services_to_clients',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'business_type',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'authorized_payment_institution',
            'money_service_business',
            'payment_service_provider',
            'other',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'Other business type',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'countries_of_business',
          type: FieldTypeEnum.country,
          isMulti: true,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'estimated_volume_of_transactions',
          type: FieldTypeEnum.number,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
          addons: {
            left: 'USD($)',
          },
        },
        {
          id: 'currencies',
          type: FieldTypeEnum.select,
          hasHelper: true,
          isMulti: true,
          isRequired: true,
          isEnabled: true,
          options: [
            'usd',
            'usdt',
            'usdc',
            'eur',
            'gbp',
            'ngn',
            'xaf',
            'xof',
            'kes',
            'zar',
            'ghs',
            'mad',
            'nad',
            'tzs',
            'others',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'Other currencies',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: 'busniess_nature_2',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'regulated_business_entity',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'has_a_license_to_operate',
          type: FieldTypeEnum.radio,
          isRequired: false,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'recent_legal_claims',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'Recent legal claims details',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'aml_regulations_compliance_failures',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'AML regulations compliance failures details',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'previous_termination_by_another_money_service_provider',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'Previous termination details',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'operations_in_a_sanctioned_country',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'compliance_1',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'compliance_officer_full_name',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'compliance_officer_title',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'compliance_officer_email',
          type: FieldTypeEnum.email,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'compliance_officer_date_of_appointment',
          type: FieldTypeEnum.date,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'Compliance team overview',
          type: FieldTypeEnum.textarea,
          hasHelper: true,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: 'compliance_2',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'aml_compliance_programme',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'No AML programme reasons',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'is_aml_programme_approved_by_the_board',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'AML programme not approved reasons',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'is_aml_programme_updated_annually',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'AML programme not annually updated reasons',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'is_aml_programme_applied_to_affiliated_companies',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'AML programme not applied to affiliated companies details',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'written_policies_against_suspicious_activities',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'No written policies against suspcious activites details',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'pep_policies',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'No PEP policies details',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'company_has_internal_or_external_auditors',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'No internal or external auditors reasons',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'business_with_shell_banks',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'Business with shell banks details',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'record_retention_policies',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'No record retention policies details',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: 'risk_assessment',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'riskbased_assessment_of_customers_and_transactions',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: ['yes', 'no', 'na'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'riskbased_assessment_of_merchants_and_transactions',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: ['yes', 'no', 'na'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'Factors part of risk-based approach of the company',
          type: FieldTypeEnum.textarea,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'level_of_customer_or_merchant_due_diligence_defined_by_the_company',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'onboarding_screening_monitoring',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'customer_or_merchant_identification_policies',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'customer_and_ubo_information_verified',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'kyc_process_continually_updated',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'monitoring_program_for_suspicious_activities',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'riskbased_assessment_for_normal_activities',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'the_company_screens_customers_against_lists_by_competent_authorities',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'Sanctions screening details',
          type: FieldTypeEnum.textarea,
          hasHelper: true,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: 'additional_information',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'additional_relevant_information',
          type: FieldTypeEnum.textarea,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: 'pdf_viewer_data_protection_clause',
    type: StepTypeEnum.terms_and_conditions,
    config: {
      pdfUrl:
        'https://kyb-onboarding.kamix.io/assets/data-protection-clause.pdf',
    },
  },
  {
    id: 'final_confirmation',
    type: StepTypeEnum.text,
  },
];
