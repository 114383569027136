import {
  AffiliatedCompanyFields,
  FieldTypeEnum,
  IndividualRoleEnum,
} from 'shared-domain';

export const affiliatedCompanyFields: AffiliatedCompanyFields[] = [
  {
    // This field is mandatory to create a company
    id: 'name',
    isRequired: true,
    isEnabled: true,
  },
  {
    // This field is mandatory to create an affiliated company
    id: 'registration_number',
    isRequired: true,
    isEnabled: true,
  },
  {
    // This field is mandatory to create a company
    id: 'country',
    type: FieldTypeEnum.country,
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'legal_form',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'position',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'registration_date',
    isRequired: true,
    isEnabled: true,
    type: FieldTypeEnum.date,
  },
  {
    id: 'roles',
    type: FieldTypeEnum.checkbox,
    isRequired: true,
    isEnabled: true,
    options: [
      IndividualRoleEnum.beneficial_owner,
      IndividualRoleEnum.legal_representative,
      IndividualRoleEnum.shareholder,
    ],
  },
  {
    id: 'ownership_percentage',
    type: FieldTypeEnum.number,
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'code',
    nested: 'classifications',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'description',
    nested: 'classifications',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'street_address',
    nested: 'address',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'street_address_2',
    nested: 'address',
    isRequired: false,
    isEnabled: true,
  },
  {
    id: 'postal_code',
    nested: 'address',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'city',
    nested: 'address',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'state',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'region',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'country',
    nested: 'address',
    type: FieldTypeEnum.country,
    isRequired: true,
    isEnabled: true,
  },
];
