// We use Fontsource to have more fonts available
// Find the font on the website: https://fontsource.org/
// and don't forget to import the custom font.
// --
// If you want used a licensed font you can add fonts files in the assets folder,
// Create the CustomFonts component with @font-face
// Then import the CustomFonts in Bootstrap inside ChakraProvider
// @doc: https://chakra-ui.com/community/recipes/using-fonts#option-2-using-font-face
import '@fontsource/prompt';
export const fonts = {
  heading: 'Prompt, sans-serif',
  body: 'Prompt, sans-serif',
};
